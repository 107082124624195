// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../images/bg-panning.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root{--bg-color: #0B7EC3;--bg-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}body{width:100vw;height:100vh;overflow:hidden}@keyframes bg-gradient{0%{background-position:0% 50%}50%{background-position:100% 50%}100%{background-position:0% 50%}}.default-cruft,[default-cruft],.b-tabs{display:none}.modal-background,.modal-card-head,.modal-card-foot,.modal.is-full-screen>.animation-content,.modal.is-full-screen>.animation-content>.modal-card,.modal-card-body{border:0;background:rgba(0,0,0,0)}", "",{"version":3,"sources":["webpack://static/styles/df23.scss"],"names":[],"mappings":"AAAA,MACE,mBAAA,CACA,mDAAA,CAGF,KACE,WAAA,CACA,YAAA,CACA,eAAA,CAWF,uBACE,GACE,0BAAA,CAGF,IACE,4BAAA,CAGF,KACE,0BAAA,CAAA,CAKJ,uCAGE,YAAA,CAIF,mKAME,QAAA,CACA,wBAAA","sourcesContent":[":root {\n  --bg-color: #0B7EC3;\n  --bg-image: url('../images/bg-panning.png');\n}\n\nbody {\n  width: 100vw;\n  height: 100vh;\n  overflow: hidden;\n}\n\n// from https://codepen.io/P1N2O/pen/pyBNzX\nbody {\n  // background: linear-gradient(-45deg, #10258a, #4DB2EA, #380b93);\n  // background-size: 400% 400%;\n  // animation: bg-gradient 15s ease infinite;\n  // height: 100vh;\n}\n\n@keyframes bg-gradient {\n  0% {\n    background-position: 0% 50%;\n  }\n\n  50% {\n    background-position: 100% 50%;\n  }\n\n  100% {\n    background-position: 0% 50%;\n  }\n}\n\n// hide default cruft like nav, tabs, etc.\n.default-cruft,\n[default-cruft],\n.b-tabs {\n  display: none;\n}\n\n// override modal styles\n.modal-background,\n.modal-card-head,\n.modal-card-foot,\n.modal.is-full-screen>.animation-content,\n.modal.is-full-screen>.animation-content>.modal-card,\n.modal-card-body {\n  border: 0;\n  background: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
